import React from "react";
import { connect } from "react-redux";

import {
    Nav, Navbar
} from "react-bootstrap";

// User actions
import WatchCar from "./sidebar_views/WatchedCars";
import Translation from "./sidebar_views/Translation";
import Bid from "./sidebar_views/Bid";
import Question from "./sidebar_views/Question";
import RequestImages from "./sidebar_views/RequestImages";
// Temporarily removed, broken by Aleado
// import RequestQuickImages from "./sidebar_views/RequestQuickImages";

// Admin actions
import RequestTranslation from "./sidebar_views/AdminActions/RequestTranslation";
import RequestBid from "./sidebar_views/AdminActions/RequestBid";

import "./AuctionActionBanner.css";

/**
 * A sticky banner for the top of the Auction Car page. Must be placed inside
 * the same container as the main content of the site, so it has the same width.
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.data
 * @param {boolean} props.loading
 * @param {boolean} props.guest
 * @param {boolean} props.isAdmin
 */
function AuctionActionBanner({
    id,
    data,
    loading,
    guest,
    isAdmin,
})
{
    // The navbar can be shown by two things: scrolling up, which shows it until
    // the user scrolls back down, or hovering over it.
    const navRef = React.useRef(null);
    const [prevYPos, setPrevYPos] = React.useState(window.pageYOffset);
    const [shownByScroll, setShownByScroll] = React.useState(true);
    const [shownByHover, setShownByHover] = React.useState(false);

    window.onscroll = () => {
        const currYPos = window.pageYOffset;
        // we scrolled down, hide the navbar if we aren't hovering over it
        if (currYPos > prevYPos)
            setShownByScroll(false);
        // we scrolled up, show the navbar
        else
            setShownByScroll(true);
        setPrevYPos(currYPos);
    };

    // when hovering over the navbar, show it
    const onMouseEnter = () => {
        setShownByHover(true);
    };
    // when not hovering over the navbar, hide it if we scrolled down
    const onMouseLeave = () => {
        setShownByHover(false);
    };

    // If either condition to show the navbar is true, show it. Otherwise,
    // shrink it by adjusting the "top" property to scroll it off the top of
    // the viewport.
    React.useEffect(() => {
        if (navRef.current)
        {
            // The value to use is defined in the CSS so it can be derived from
            // other CSS variables
            const navStyle = getComputedStyle(navRef.current);
            const hiddenOffset = navStyle.getPropertyValue("--hidden-top");
            if (shownByHover || shownByScroll)
                navRef.current.style.top = "0px";
            else
                navRef.current.style.top = hiddenOffset;
        }
    }, [shownByScroll, shownByHover, navRef]);

    // Don't render yet if we're loading, but still render if we failed to fetch
    // the auction car data. That way we can still fetch data like translations
    // even if the car isn't up for auction anymore.
    if (loading)
    {
        return null;
    }

    const navItems = [];
    if (guest)
    {
        let guestText = "Create a user account for more features";
        if (data?.auction_house?.startsWith("USS"))
            guestText += ", including requesting higher-resolution images " +
                "from USS auctions.";
        else
            guestText += ".";
        navItems.push(guestText);
    }
    else
    {
        navItems.push(
            <WatchCar id={id}/>,
            <Translation id={id}/>,
            <Bid id={id}/>,
            <Question/>,
            // Temporarily removed, broken by Aleado
            /*<RequestQuickImages
                lot={data?.lot}
                auction_time={data?.auction_time}
                auction_house={data?.auction_house}
            />,*/
            <RequestImages id={id}/>,
        );
        if (isAdmin)
        {
            navItems.push(
                <RequestTranslation/>,
                <RequestBid/>,
            );
        }
    }

    return <Navbar className="AuctionActionBanner" ref={navRef}
        onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
    >
        <Nav>
            {navItems.map((content, i) => <Nav.Item key={i}>
                {content}
            </Nav.Item>)}
        </Nav>
    </Navbar>;
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,
        guest: state.profile.guest,
        isAdmin: state.profile.is_admin,
    };
};

/**
 * A sticky banner for the top of the Auction Car page. Must be placed inside
 * the same container as the main content of the site, so it has the same width.
 * @param {Object} props
 * @param {string} props.id
 */
export default connect(mapStateToProps)(AuctionActionBanner);
