import React, { Component } from "react";
import "./App.css";
import "./AuctionCar.css";
import { connect } from "react-redux";
import moment from "moment-timezone";

import {
    Container, Row, Col,
    Card,
    Button,
    Table,
} from "react-bootstrap";

import { setAuctionCarDetail, getAuctionCarData, clearAuctionData } from "./actions/auction_car_actions";
import PCANav from "./dashboard-components/navbar";

import AuctionActionBanner from "./auction_car_views/AuctionActionBanner";
import AuctionCarImages from "./auction_car_views/AuctionCarImages";
import AuctionCarDetails from "./auction_car_views/AuctionCarDetails";
import AuctionTimeToast from "./auction_car_views/AuctionTimeToast";
import TranslationTable from "./auction_car_views/TranslationTable";
import commaNumbers from "./widgets/commaNumbers";
import QuestionModal from "./auction_car_views/ask_question_modal";
import Questions from "./auction_car_views/questions";
import PreviousAuctions from "./auction_car_views/PreviousAuctions";
import ProductionData from "./dashboard-components/productionData";

class AuctionCar extends Component {
    componentDidMount () {
        // Note: translations, image requests, bids and questions data are all
        // fetched by their sidebar buttons (components in AuctionActionBanner)
        getAuctionCarData(this.props.match.params.auction_id);
    }

    componentWillUnmount () {
        this.props.clearAuctionData();
    }

    getAuctionTime() {
        // data or auction time are not known
        if (!this.props.data?.auction_time)
            return <span>Date not set</span>;
        const time = moment.tz(this.props.data.auction_time, "Asia/Tokyo");
        // if the date is over 7 days away, it may be a placeholder
        if (time.isAfter(moment().add(7, "days")))
            return <span>Date not set</span>;
        // if the auction time is known and not a placeholder, return it
        return <span>
            {time.format("D MMM YYYY HH:mm z")}, {time.fromNow()}
        </span>;
    }

    getStartingBid() {
        // data or starting bid not known
        if (!this.props.data?.start_price_num)
            return null;
        else
            return <>&yen;{commaNumbers(this.props.data.start_price_num)}</>;
    }

    /**
     * Return the Auction details, but if a translation is already being shown,
     * only show details that wouldn't be in the translation.
     * @param {boolean} translationShown 
     */
    getAuctionDetails(translationShown) {
        if (translationShown) {
            return <Table striped bordered size="sm" hover>
                <tbody>
                    <tr>
                        <td><b>Auction time</b></td>
                        <td>{this.getAuctionTime()}</td>
                    </tr>
                    <tr>
                        <td><b>Starting bid</b></td>
                        <td>{this.getStartingBid()}</td>
                    </tr>
                </tbody>
            </Table>;
        }
        else {
            return <AuctionCarDetails id={this.props.match.params.auction_id}/>;
        }
    }

    productionDataCard() {
        return <Card>
            <Card.Header>Look up more production data</Card.Header>
            <Card.Body className="flush_card">
                <Container>
                    Enter the make and full chassis number of this vehicle
                    <ProductionData data={this.props.data}/>
                </Container>
            </Card.Body>
        </Card>;
    }

    previousAuctionsCard() {
        return <Card>
            <Card.Header>Previous sales of this vehicle</Card.Header>
            <Card.Body className="flush_card">
                <PreviousAuctions/>
            </Card.Body>
        </Card>;
    }

    render() {
        const auctionID = this.props.match.params.auction_id;
        const showTranslation = this.props.translation_loaded
            && this.props.requested_translation
            && this.props.translation_completed;

        return <>
            <div>
                <PCANav/>
                <Container>
                    {/* Place in container w/ page content so it's resized to be
                        the same width */}
                    <AuctionActionBanner id={auctionID}/>
                    <Card>
                        <Card.Header>
                            Car Images
                            <Button size="sm" style={{float: "right"}}
                                href={"#/print_auction_car/" + auctionID}
                                target="_blank" rel="noreferrer"
                            >
                                Print Auction Details
                            </Button>
                        </Card.Header>
                        <Card.Body className="AuctionCarCardBody">
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <div className="CarImagesContainer">
                                            <AuctionCarImages
                                                separateSheet={false}
                                                size={12}/>
                                        </div>
                                    </Col>
                                    <Col md={6} className="AuctionDetailsCol">
                                        {showTranslation && <>
                                            <h2>Auction Sheet Translation</h2>
                                            <TranslationTable/>
                                        </>}
                                        <h2>Auction Information</h2>
                                        {this.getAuctionDetails(showTranslation)}
                                        <Questions auction_id={auctionID}/>
                                        {this.productionDataCard()}
                                        {this.previousAuctionsCard()}
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
            <AuctionTimeToast/>
            <QuestionModal auction_id={auctionID}/>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,

        translation_loaded: state.auction_car.translation_loaded,
        requested_translation: state.auction_car.requested_translation,
        translation_completed: state.auction_car.translation_completed

    };
};

const mapDispatchToProps = dispatch => ({
    setAuctionCarDetail: (car_data) => dispatch(setAuctionCarDetail(car_data)),
    clearAuctionData: () => dispatch(clearAuctionData())
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCar);
