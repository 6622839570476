import React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";

import "./AuctionTimeToast.css";

/**
 * Displays the auction time from the currently-loaded auction car data.
 * @param {Object} props
 * @param {Object | null} [props.data] auction data from redux
 */
function AuctionTimeToast({
    data
})
{
    const [dismissed, setDismissed] = React.useState(false);

    // Don't display a toast if there isn't any auction data loaded.
    if (!data)
        return null;

    let body = <p>Auction date not set.</p>;
    // Auction times are in JST
    const auctionTimeJST = moment.tz(data.auction_time, "Asia/Tokyo");
    // Only attempt to render it if there's actually a date/time set, and it's
    // less than 7 days away (more than 7 days away may be a placeholder for an
    // unset date).
    if (data.auction_time && auctionTimeJST.isBefore(moment().add(7, "days")))
    {
        // For converting to local timezone
        const localTZ = moment.tz.guess();
        // If it's at midnight, the auction date is set but not the time. If the
        // time isn't set and the date hasn't passed, display a special format
        // with the date and the range of time it corresponds to locally.
        if (auctionTimeJST.hour() === 0 && auctionTimeJST.minute() === 0
            && moment(auctionTimeJST).add(1, "day") >= moment())
        {
            const localDayStart = moment.tz(auctionTimeJST, localTZ);
            const localDayEnd = moment(localDayStart).add(1, "day");
            body = <p>
                {auctionTimeJST.format("D MMM YYYY z")} (time not set)
                <br/>
                Between {localDayStart.format("D MMM YYYY HH:mm ")}
                - {localDayEnd.format("D MMM YYYY HH:mm z")}
            </p>;
        }
        // If it isn't at midnight (or it's at midnight but the date has passed)
        // show the exact time in JST and local time, and the relative time.
        else
        {
            const auctionTimeLocal = moment.tz(auctionTimeJST, localTZ);
            body = <p>
                {auctionTimeJST.format("D MMM YYYY HH:mm z")}
                <br/>
                {auctionTimeLocal.format("D MMM YYYY HH:mm z ")}
                ({auctionTimeLocal.fromNow()})
            </p>;
        }
    }
    return <Toast className="AuctionTimeToast"
        show={!dismissed}
        onClose={() => setDismissed(true)}
    >
        <Toast.Header>
            {/* m[argin]r[ight]-auto to right-justify close button */}
            <strong className="mr-auto">Auction Time</strong>
        </Toast.Header>
        <Toast.Body>{body}</Toast.Body>
    </Toast>;
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
    };
};

/**
 * Displays the auction time from the currently-loaded auction car data.
 */
export default connect(mapStateToProps)(AuctionTimeToast);
