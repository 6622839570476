import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";

/**
 * Returns a formatted table with all translations fields.
 */
function TranslationTable({translationData})
{
    // no data? don't render anything
    if (translationData?.found !== true)
        return null;

    // add table rows for all fields in `main`
    const rows = [];
    try
    {
        const main = JSON.parse(translationData.main);
        for (const k in main)
        {
            rows.push(<tr key={k}>
                <td>{k}</td><td>{main[k]}</td>
            </tr>);
        }
    }
    catch (e)
    {
        console.log("Failed to parse translation.main: " + e);
    }

    // add table rows for fields in the translation object other than `main`
    const EXPECTED_KEYS = [
        "notes", "sales_report", "report", "diagram_notes", "exporter_notes"
    ];
    for (const k of EXPECTED_KEYS)
    {
        const displayName = k[0].toUpperCase + k.slice(1).replaceAll("_", " ");
        if (translationData[k])
        {
            rows.push(<tr key={k}>
                <td>{displayName}</td><td>{translationData[k]}</td>
            </tr>);
        }
    }

    return <Table striped bordered size="sm" hover className="TranslationTable">
        <tbody>
            {rows}
        </tbody>
    </Table>;
}

const mapStateToProps = state => {
    return {
        translationData: state.auction_car.translation
    };
};

/**
 * Returns a formatted table with all translations fields.
 */
export default connect(mapStateToProps)(TranslationTable);
