import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../App.css";
import { connect } from "react-redux";

import {
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import {
    setBidData
} from "../../actions/auction_car_actions";

import { settings } from "../../settings";

import commaNumber from "../../widgets/commaNumbers";

import CreateBidModal from "../bid_modals/create_bid_modal";
import CreateBidConfirmModal from "../bid_modals/create_bid_confirm_modal";
import UpdateBidModal from "../bid_modals/update_bid_modal";
import UpdateBidConfirmModal from "../bid_modals/update_bid_confirm_modal";
import CancelBidConfirmModal from "../bid_modals/cancel_bid_confirm_modal";

import { showCreate, showUpdate } from "../../actions/user_bid_modal_actions";

class Bid extends Component {
    constructor() {
        super();
        this.fetchBid = this.fetchBid.bind(this);
    }

    fetchBid() {
        const url = settings.api_server + "/bids/bid/" + this.props.id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a car data
                this.props.setBidData(data.has_bid, data.amount, data.cancelled, data.bidding_possible, data.message, data.comment);
            }.bind(this));
    }

    componentDidMount() {
        this.fetchBid();
    }

    /**
     * Helper for render(), for cases where we must disable the bid button.
     * Can be passed a string to use for a tooltip to explain why the button is
     * disabled.
     * @param {string} [tooltip]
     */
    disabledButton(tooltip) {
        const buttonText = this.props.has_bid ? "Update bid" : "Place bid";
        if (tooltip) {
            // Since the button is disabled, we need to wrap it in a div to
            // trigger the tooltip because disabled elements don't generate
            // pointer events.
            return <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{tooltip}</Tooltip>}
            >
                <div>
                    <Button disabled>{buttonText}</Button>
                </div>
            </OverlayTrigger>;
        }
        else {
            return <Button disabled>{buttonText}</Button>;
        }
    }

    /**
     * Helper for render(), when the auction can still be bid on and the user
     * already has a bid. Includes the appropriate modals.
     */
    updateButton() {
        const tooltipInfo = [];
        if (this.props.bid_cancelled) {
            tooltipInfo.push(<span key="bid_cancelled_p">
                Bid cancelled
            </span>);
        }
        else {
            tooltipInfo.push(<span key="bid_amount_p">
                Bid set for &yen;{commaNumber(this.props.amount)}
            </span>);
        }
        // possible message related to auction time
        if (this.props.bidMessage) {
            tooltipInfo.push(<br/>, <span key="bid_time_p">
                {this.props.bidMessage}
            </span>);
        }
        return <div>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{tooltipInfo}</Tooltip>}
            >
                <Button onClick={this.props.showUpdate}>
                    Update bid
                </Button>
            </OverlayTrigger>
            <UpdateBidModal />
            <UpdateBidConfirmModal id={this.props.id} fetchBid={this.fetchBid} />
            <CancelBidConfirmModal id={this.props.id} fetchBid={this.fetchBid} />
        </div>;
    }

    render() {
        // Loading bid or auction data
        if (!this.props.loaded || this.props.auction_loading) {
            return this.disabledButton("Loading...");
        }
        // Failed to load auction data
        if (this.props.auction_failure) {
            return this.disabledButton("Failed to load auction data.");
        }
        // Can't create or update due to auction time
        if (!this.props.carIsBiddable) {
            return this.disabledButton(this.props.bidMessage);
        }

        // At this point, if we already have a bid, we can update it. We don't
        // check props.can_bid before this point because it'll be false if we
        // have no requests remaining, but updating an existing bid doesn't
        // consume a request.
        if (this.props.has_bid) {
            return this.updateButton();
        }

        // Can't create new bids due to lack of requests
        if (this.props.bids_available === 0) {
            return this.disabledButton("No bids available, cannot bid.");
        }
        // Can't create new bids due to deposit
        if (!this.props.can_bid) {
            return this.disabledButton("Insufficient deposit to bid.");
        }

        // Can create a bid, show remaining requests if they aren't infinite
        let buttonText = "Place bid";
        if (this.props.bids_available > 0)
            buttonText += " (" + this.props.bids_available + " left)";
        return <div>
            <Button onClick={this.props.showCreate}>
                {buttonText}
            </Button>
            <CreateBidModal id={this.props.id} />
            <CreateBidConfirmModal id={this.props.id} fetchBid={this.fetchBid} />
        </div>;
    }
}

Bid.propTypes = {
    id: PropTypes.string.isRequired,
};

const bidState = state => {
    return {
        loaded: state.auction_car.bid_loaded,
        has_bid: state.auction_car.has_bid,
        bid_cancelled: state.auction_car.is_cancelled,
        amount: state.auction_car.bid_amount,
        // false if the auction has passed
        carIsBiddable: state.auction_car.carIsBiddable,
        // explains why carIsBiddable is false, or warns about auctions which
        // may not be possible to bid on
        bidMessage: state.auction_car.bidMessage,
        // true if the user has the minimum deposit to make a bid, and has at
        // least one bid request available
        can_bid: state.profile.can_bid,
        bids_available: state.profile.bids_available,
        balance: state.profile.balance,

        // state of auction data fetch, disable bid buttons if it's still
        // loading or failed to fetch
        auction_loading: state.auction_car.loading,
        auction_failure: state.auction_car.failure,

        fxLoaded: state.fx.loading, // fx data
        fx_data: state.fx.fx_data
    };
};

const bidDispatch = dispatch => ({
    setBidData: (has_bid, amount, can, canBid, bidMessage, comm) => dispatch(setBidData(has_bid, amount, can, canBid, bidMessage, comm)),
    showCreate: () => dispatch(showCreate()),
    showUpdate: () => dispatch(showUpdate())
});
export default connect(bidState, bidDispatch)(Bid);
