import React, { Component } from "react";
import "../../App.css";
import { connect } from "react-redux";

import {
    Button
} from "react-bootstrap";

import {
    setWatchData
} from "../../actions/auction_car_actions";

import { settings } from "../../settings";
import { setMessage } from "../../actions/alert_actions";

class WatchCar extends Component {
    constructor () {
        super();
        this.watchToggle = this.watchToggle.bind(this);
    }

    watchToggle () {
        const url = settings.api_server + "/watch/" + this.props.id;

        let method = "POST";
        if (this.props.isWatched) { method = "DELETE"; }

        // do a simple toggle
        // might get out of state if someone madly clicks.
        this.props.setWatchData(!this.props.isWatched);

        fetch(url, {
            credentials: "include",
            method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                if (!data.success) { this.props.setMessage("Problem setting watch"); }
            }.bind(this));
    }

    componentDidMount() {
        this.fetchWatch();
    }

    // This is here so when part of watchCarState is updated, we re-check if
    // there's a watch. For example, if the user places a bid, has_bid will be
    // set and a watch will be created, which we need to fetch.
    componentDidUpdate() {
        this.fetchWatch();
    }

    fetchWatch () {
        /*
        Fetch if the user is watching this car, or has translations for this car
        */

        const url = settings.api_server + "/watch/" + this.props.id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a car data
                this.props.setWatchData(data.watched);
            }.bind(this));
    }

    render () {
        return <Button onClick={this.watchToggle} disabled={!this.props.loaded}>
            {this.props.isWatched ? "Unwatch" : "Watch"} this car
        </Button>;
    }
}

const watchCarState = state => {
    return {
        loaded: state.auction_car.watch_loaded,
        isWatched: state.auction_car.isWatched,
        // Changes in the following items indicate an action has been taken that
        // created a watch, so we need to re-fetch the watch. Having them here
        // triggers an update when they change.
        translation: state.auction_car.requested_translation,
        hasBid: state.auction_car.has_bid,
        questionThread: state.questions.thread_data,
        images: state.image_request_list.hasRequestedImages
    };
};

const watchCarDispatch = dispatch => ({
    setWatchData: (isWatch) => dispatch(setWatchData(isWatch)),
    setMessage: (msg) => dispatch(setMessage(msg))
});

export default connect(watchCarState, watchCarDispatch)(WatchCar);
