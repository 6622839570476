import React, { Component } from "react";
import "../../App.css";
import { connect } from "react-redux";

import {
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import { toggleAskModal } from "../../actions/questions_actions";


class Question extends Component {

    constructor(){
        super();
        this.canAskQuestions = this.canAskQuestions.bind(this);
    }

    canAskQuestions() {
        return this.props.can_bid ||
            this.props.can_request_translation ||
            this.props.is_admin ||
            this.props.isvip;
    }

    render() {
        let reason = null;
        if (this.props.loading) {
            reason = "Loading...";
        }
        else if (this.props.failure) {
            reason = "Failed to load auction data.";
        }
        else if (!this.canAskQuestions()) {
            reason = "Questions available upon deposit.";
        }

        // If there's a reason we can't ask a question, show a tooltip and
        // disable the button.
        if (reason) {
            return <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{reason}</Tooltip>}
            >
                <div>
                    <Button disabled>
                        Ask a question
                    </Button>
                </div>
            </OverlayTrigger>;
        }
        // Otherwise, enable the button.
        else {
            return <Button onClick={this.props.toggleAskModal}>
                Ask a question
            </Button>;
        }
    }
}
const questionState = state => {
    return {
        can_bid: state.profile.can_bid,
        can_request_translation: state.profile.can_request_translation,
        is_admin: state.profile.is_admin,
        isvip: state.profile.isvip,
        loading: state.auction_car.loading,
        failure: state.auction_car.failure,
    };
};

const questionDispatch = dispatch => ({
    toggleAskModal: () => dispatch(toggleAskModal()), // for opening the modal
});

export default connect(questionState, questionDispatch)(Question);
