import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../App.css";
import { connect } from "react-redux";

import moment from "moment-timezone";

import {
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import {
    setTranslation
} from "../../actions/auction_car_actions";

import { settings } from "../../settings";

class Translation extends Component {
    constructor () {
        super();
        this.fetchTranslation = this.fetchTranslation.bind(this);
        this.requestTranslation = this.requestTranslation.bind(this);
    }

    requestTranslation () {
        const url = settings.api_server + "/translations/access/" + this.props.id;

        fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // refresh data
                this.fetchTranslation();
            }.bind(this));
    }

    fetchTranslation () {
        const url = settings.api_server + "/translations/access/" + this.props.id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a translation data
                this.props.setTranslation(data.found, data, data.translation_completed);
            }.bind(this));
    }

    componentDidMount () {
    /*
        Fetch if the user has translations for this car
        */
        this.fetchTranslation();
    }

    render () {
        // will be used to disable the button
        let disabled = false;
        // the text in the button
        let text = "Request translation";
        // if putting the reason for the button being disabled inside of the
        // button would make it too large, use a tooltip instead
        let tooltip = null;
        // loading: disable the button
        if (!this.props.loaded ||
            !this.props.dataLoaded ||
            !this.props.userDataLoaded)
        {
            disabled = true;
        }
        // failed to load: disable with tooltip
        else if (this.props.carDataFailure)
        {
            disabled = true;
            tooltip = "Failed to load vehicle data";
        }
        // translation requested: disable button and change text
        else if (this.props.requested_translation)
        {
            disabled = true;
            // not fullfilled:
            if (this.props.translation !== null && !this.props.translation_completed)
                text = "Translation pending";
            // fullfilled:
            else
                text = "Translation complete";
        }
        // successfully loaded, but can't request a translation
        else if (!this.props.can_request_translation)
        {
            disabled = true;
            tooltip = "Insufficient translation requests";
        }
        // successfully loaded and user is able to request translations
        else
        {
            // check if the auction has passed
            const timeToAuction = moment.tz(this.props.carData.auction_time, "Asia/Tokyo");
            if (timeToAuction.isBefore())
            {
                disabled = true;
                tooltip = "Auction has passed, cannot request translation";
            }
            // -1 indicates unlimited requests. If our requests are limited,
            // display how many are left (note that can_request_translation
            // would be false if we had 0 requests, so we don't need to check
            // for disabling the button here)
            else if (this.props.translation_requests_available >= 0)
            {
                text += " (" + this.props.translation_requests_available + " left)";
            }
        }

        if (tooltip)
        {
            // need to wrap the button in a div in case it's disabled, because
            // disabled elements don't generate pointer events
            return <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{tooltip}</Tooltip>}
            >
                <div>
                    <Button disabled={disabled} onClick={this.requestTranslation}>
                        {text}
                    </Button>
                </div>
            </OverlayTrigger>;
        }
        else
        {
            return <Button disabled={disabled} onClick={this.requestTranslation}>
                {text}
            </Button>;
        }
    }
}

Translation.propTypes = {
    id: PropTypes.string.isRequired,
};

const translationState = state => {
    return {
        loaded: state.auction_car.translation_loaded,
        requested_translation: state.auction_car.requested_translation,
        translation_completed: state.auction_car.translation_completed,
        translation: state.auction_car.translation,

        dataLoaded: !state.auction_car.loading, // auction car data for auction time
        carData: state.auction_car.data,
        translation_requests_available: state.profile.translation_requests_available,
        can_request_translation: state.profile.can_request_translation,
        carDataFailure: state.auction_car.failure,

        userDataLoaded: state.profile.profileLoaded, // user data for bid check stuff
        can_bid: state.profile.can_bid

    };
};

const translationDispatch = dispatch => ({
    setTranslation: (req, trans, com) => dispatch(setTranslation(req, trans, com))
});

export default connect(translationState, translationDispatch)(Translation);
